// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl:'http://localhost:4000',
  production: true,
  testing: false,
  firebase:{
    apiKey: "AIzaSyDjoVeSP_Fc0nHb9Y9jikCMAMxLV94dzp8",
    authDomain: "ecommerce-mpu.firebaseapp.com",
    databaseURL: "https://ecommerce-mpu.firebaseio.com",
    projectId: "ecommerce-mpu",
    storageBucket: "ecommerce-mpu.appspot.com",
    messagingSenderId: "280187230268",
    appId: "1:280187230268:web:a8dc4beea72ce4c2b04b39",
    measurementId: "G-222Z6JT65X"
  }
};

if(location.hostname == 'localhost'){
  environment.production = false;
  environment.testing = true;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
