import { Component, OnInit, ɵConsole } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../../shared/services/product.service';
import { CartService } from '../../shared/services/cart.service';
import {Router} from '@angular/router'
import { ProductComponent } from '../products/product/product.component';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.sass']
})
export class HomeTwoComponent implements OnInit {


  products: Product[];
  public banners = [];

  shoppingCartItems: CartItem[] = [];
  wishlistItems  :   Product[] = [];

  public featuredProducts: Array<Product>;
  public onSaleProducts: Array<Product>;
  public topRatedProducts: Array<Product>;
  public newArrivalsProducts: Array<Product>;
  public categorias = JSON.parse(localStorage.getItem('categoriasMPU'));

  /*
  public slides = [
    { title: 'En descuento', subtitle: '75%', image: 'assets/images/carousel/banner1.jpg' },
    { title: 'Productos mas vendidos', subtitle: 'Selección especial', image: 'assets/images/carousel/banner4.jpg' },
  ];
  */
 public slides = [];

  constructor(private productService: ProductService, 
              private cartService: CartService,
              private router: Router) { }

  ngOnInit() {
    if (location.pathname == '/'){
      this.router.navigate(['home'])
    }
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.productService.getProductosConLimite('10').subscribe((products: any) => {
        this.products = [];
        
        products.forEach(producto => {
          let categoria = undefined;
          if(this.categorias){
            categoria = this.categorias.find(categoria => categoria.id == producto.data.TallerLineaProducto)
          }
          if(producto.data.Nombre != '' && producto.data.Valor >= 1000 && producto.data.Stock > 0) {
            this.products.push({
              id: producto.id,
              name: producto.data.Nombre,
              price: producto.data.Valor,
              salePrice: producto.data.ValorConDescuento,
              discount: producto.data.PorcentajeDescuento,
              pictures: [{
                big: producto.data.ImgUrl,
                small: producto.data.ImgUrl,
              }], 
              shortDetails: producto.data.DescripcionCorta,
              description: producto.data.Descripcion,
              stock: producto.data.Stock,
              brand: producto.data.Taller,
              sale: (producto.data.Saldo == 'Si') ? true : false,
              category: (categoria) ? categoria.name : '-',
              user: producto.data.User,
              stars: (producto.data.Estrellas == undefined ) ? 0 : producto.data.Estrellas,
              nationalShipmentCost: producto.data.CostoEnvioNacional,
              localShipmentCost: producto.data.CostoEnvioLocal,
              shipmentTime: producto.data.TiempoEntrega,
              department: producto.data.Departamento,
              city: producto.data.Ciudad
            })
          }
        })
      
    })
    this.productService.getBanner().subscribe((banner: any) => {
      this.slides = [];
      banner.forEach(bann => {
        this.slides.push({
          image: bann.data.image,
          subtitle: bann.data.subtitle,
          title: bann.data.title,
          url: bann.data.url
        })
      })
    })
  } 


}
