import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Product} from "../../modals/product.model";
import {CartItem} from "../../modals/cart-item";
import {ProductService} from "../shared/services/product.service";
import {CartService} from "../shared/services/cart.service";
import { Router, NavigationEnd } from '@angular/router';
import { SidebarMenuService } from '../shared/sidebar/sidebar-menu.service';
import { SidenavMenu } from '../shared/sidebar/sidebar-menu.model';

import { AuthService } from '../shared/services/auth.service'
import { Observable } from 'rxjs';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent implements OnInit {

  public sidenavMenuItems:Array<any>;

  public currencies = ['USD', 'EUR'];
  public currency:any;
  public flags = [
    { name:'English', image: 'assets/images/flags/gb.svg' },
    { name:'German', image: 'assets/images/flags/de.svg' },
    { name:'French', image: 'assets/images/flags/fr.svg' },
    { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;

  products: Product[];

  indexProduct: number;
  shoppingCartItems: CartItem[] = [];

  public banners = [];

  wishlistItems  :   Product[] = [];

  public url : any;

  navItems: SidenavMenu[] = [
    {
      displayName: 'Inicio',
      iconName: 'recent_actors',
      route: '/home'
    },
    {
      displayName: 'Productos',
      iconName: 'feedback',
      route: '/home/products/2YGaFIwgjIhHT1kofBXG'
    },
    {
      displayName: 'Contáctanos',
      iconName: 'movie_filter',
      route: '/pages/contact'
    }
  ];

  public user = this.authScv.afAuth.user;
  public userInfo: any = {};

  constructor(public router: Router, private cartService: CartService, public sidenavMenuService:SidebarMenuService, public authScv: AuthService) {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.authScv.user.subscribe(user => {
      this.userInfo = user;
    })
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.url = event.url;
    //   }
    // } )
  }
  async onLogout(){
    try {
      await this.authScv.logout();
      this.router.navigate(['/home']);
    } catch (err) {
      console.log(err);
    }
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];
  }

  public changeCurrency(currency){
    this.currency = currency;
  }
  public changeLang(flag){
    this.flag = flag;
  }
}
