import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  private adminAPI = 'https://us-central1-orlondonol-2e688.cloudfunctions.net/app'
  adminHeader = new HttpHeaders()


  constructor(private httpClient: HttpClient) {
    this.adminHeader.append('Content-Type','application/json')
  }

  public sendNewsletterEmail(dest: string){
    return this.httpClient.get(`${this.adminAPI}/correo/${dest}`)
  }
}
