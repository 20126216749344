import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  public showSnackBar(message: string){
    return this.snackBar.open(message, 'x', { verticalPosition: 'top',panelClass: ['success'], duration: 3000})
  }
}
