import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {MessagesService} from '../services/messages.service'


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: any;
  public authState: any;

  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore, private msj: MessagesService) {
    this.user = this.afAuth.authState.pipe(
      switchMap((user) => {
        if(user) {
          return this.db.doc<any>(`usuarios/${user.uid}`).valueChanges();
        }
      })
    )
   }

  async register(user): Promise<any> {
    /*
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(
        user.email,
        user.password
      );
      return result;
    } catch(error){
      
    }
    */
    const result = await this.afAuth.createUserWithEmailAndPassword( user.email, user.password)
   .then( userCredential => {
    this.user = user;
    this.insertData(userCredential)
    .then(() => {
      return result
    })
   })
   .catch( err => {
     
   })
    
  }
  async insertData(userData: firebase.auth.UserCredential){
    return this.db.doc<any>(`usuarios/${userData.user.uid}`).set({
      name: this.user.name,
      address: this.user.address,
      cellphone: this.user.cellphone,
      email: this.user.email,
      city: this.user.city,
      depto: this.user.depto
    })
  }
  async login(email: string, password: string): Promise<any> {
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      return result;
    } catch(error){
      if(error.code == 'auth/invalid-email'){
        this.msj.mensajeError('Error', 'Digite un correo electrónico válido');
      }
      else{
        this.msj.mensajeError('Error', 'Usuario o contraseña incorrectos');
      }

    }
    
  }
  async logout(): Promise<void> {
    try {
      await this.afAuth.signOut();
    }
    catch(err) {
      
    }
  }

  forgetPassword(email){
    return this.afAuth.sendPasswordResetEmail(email);
  }
}
