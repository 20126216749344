import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { ProductLeftSidebarComponent } from './products/product-left-sidebar/product-left-sidebar.component';
import { HomeTwoComponent } from './home-two/home-two.component';
import { MainComponent } from '../main/main.component';
import { HomeComponent } from './home/home.component';

import { Resolver } from '../../modals/resolver'


// Routes
const routes: Routes = [
  { 
    path: '',
    children:[
      { path: '', component: HomeTwoComponent },
      { path: 'products/:category', component: ProductLeftSidebarComponent},
      { path: 'product/:id', component: ProductDetailsComponent }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [
  ],
})


export class ShopRoutingModule { }
