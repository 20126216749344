import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  header = new HttpHeaders();
  private adminAPI = 'https://us-central1-orlondonol-2e688.cloudfunctions.net/app'
  constructor(private httpClient: HttpClient) {
    this.header.append('Content-Type','application/json; charset=utf-8');
  }

  public createClient(client){
    return this.httpClient.post(`${this.adminAPI}/clientes`, client, {headers: this.header}).toPromise()
  }
}
